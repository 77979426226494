import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { FeatureFlag } from '@app/enums';
import { FeatureOptions } from '@app/interfaces';
import { FeatureService } from '@app/services/feature.service';
import { error, redirect } from './helpers';

@Injectable()
/**
 * Use this to redirect a user if they don't have a set of feature flags.
 * Otherwise, they will still continue to the original route.
 */
export class DoesntHaveFeatureRedirectGuard {
    constructor(
        private featureService: FeatureService,
        private router: Router
    ) {}

    async canActivate(routeSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        const featureOptions = (routeSnapshot.data as FeatureOptions).feature;
        const { flags, redirectTo } = featureOptions;

        if (!flags || !redirectTo) {
            error(state.url);
        }

        const hasFeatureFlags = (
            await Promise.all(flags.map((flag: FeatureFlag) => this.featureService.has(flag)))
        ).every((hasFlag: boolean) => hasFlag);

        if (!hasFeatureFlags) {
            redirect(this.router, routeSnapshot.params, redirectTo);
        }

        // Always want to pass through to original route if no redirect occurs.
        return Promise.resolve(true);
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return this.canActivate(childRoute, state);
    }
}
